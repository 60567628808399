import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import dayjs from '@/lib/dayjs'
import { pageStartIndex } from '@/utils/table'
import { mapGetters } from 'vuex'

export default {
  components: { EmptyBlock, DeleteDialog },
  data: () => ({
    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
      dataCount: 0,
    },
    search: {},
    dialog: {
      delete: false,
      create: false,
      edit: false,
    },
    loading: false,
  }),
  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
  },
  methods: {
    dateFormat (date, defaultStr = '無') {
      if (!date) return defaultStr
      return dayjs(date).format('YYYY/MM/DD HH:mm')
    },
    extendDialog (types) {
      types.forEach(type => {
        this.$set(this.dialog, type, false)
      })
    },
    extendSearch (types) {
      types.forEach(type => {
        this.$set(this.search, type, false)
      })
    },
    showErrorMsg (err) {
      if (err) {
        this.$message.error(err)
        return true
      }
      return false
    },
  },
}
