<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible="true"
    title="新增 Email"
    @close="$emit('close')"
  >
    <BaseElForm
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem label="Email 接收通知" prop="email">
        <BaseElInput
          v-model="formData.email"
          placeholder="請輸入電子信箱"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="handleSave">
        新增
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import formUtils from '@/utils/form'
import { noEmptyRules, emailRules } from '@/validation'
import { CreateShopEmailNotify } from '@/api/shop'
import { mapGetters } from 'vuex'

export default {
  name: 'NotifyEmailDialog',
  props: {
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    formData: {
      email: null,
    },
    formRules: {
      email: [noEmptyRules(), emailRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
  },
  methods: {
    async handleSave () {
      if (!await formUtils.checkForm(this.$refs.form)) return
      if (!await this.createShopEmailNotify()) return
      this.$emit('refresh')
      this.$emit('close')
    },

    async createShopEmailNotify () {
      const [, err] = await CreateShopEmailNotify({
        shopId: this.shop,
        email: this.formData.email.trim(),
      })
      if (err) {
        this.$message.error(err)
        return false
      }
      this.$message.success('創建成功！')
      return true
    },
  },
}
</script>

<style lang="postcss" scoped>

</style>
